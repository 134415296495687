<template>
  <div>
    <h3 class="center">События</h3>
    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>
      <table class="t_table">
        <tr>
          <th style="width: 5%">№</th>
          <th style="width: 80%">Название события</th>
          <th style="width: 15%">Статус</th>
        </tr>
        <template v-if="rowsCount">
          <tr v-for="(row, y) in report" :key="y">
            <td>{{ y + 1 }}</td>
            <td>{{ row.job_name }}</td>
            <td>{{ row.job_rate }}</td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td class="center" colspan="300">нет данных</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  components: { Loader },
  data() {
    return {
      loading: false,
      report: [],
      rowsCount: 0,
    };
  },

  mounted() {
    this.tableDataRetrieve();
  },

  methods: {
    async tableDataRetrieve() {
      this.loading = true;

      const data = [];
      this.report = data;

      this.rowsCount = data.length;
      this.loading = false;
    },
  },
};
</script>
